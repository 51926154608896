import React from 'react';
import licenceVolumeDiscounts from 'helpers/lib/licenceVolumeDiscounts';

function useSubscriptionAutoDiscounts(
  licencesCount,
  contractTermLength,
  paymentFrequency
) {
  return React.useMemo(() => {
    const autoDiscounts = [];

    const volumeDiscount = licenceVolumeDiscounts.find((discount) => {
      return (
        licencesCount >= discount.licencesCountRange[0]
        && licencesCount <= (discount.licencesCountRange[1] || Infinity)
      );
    });
    if (volumeDiscount) {
      const range = volumeDiscount.licencesCountRange[1]
        ? `${Intl.NumberFormat('en').format(
            volumeDiscount.licencesCountRange[0]
          )}–${Intl.NumberFormat('en').format(
            volumeDiscount.licencesCountRange[1]
          )}`
        : `${new Intl.NumberFormat('en').format(
            volumeDiscount.licencesCountRange[0]
          )}+`;
      autoDiscounts.push({
        value: volumeDiscount.value,
        label: `${
          volumeDiscount.value * 100
        }% volume discount (${range} licences)`,
      });
    }

    if (
      paymentFrequency === 'prepaid'
      && (contractTermLength === '2 year' || contractTermLength === '3 year')
    ) {
      autoDiscounts.push({
        type: 'prepaid',
        value: 0.05,
        label: '5% discount for prepaid',
      });
    }

    return autoDiscounts;
  }, [licencesCount, contractTermLength, paymentFrequency]);
}

export default useSubscriptionAutoDiscounts;
