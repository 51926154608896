// We need to disable this because some endpoints
// expect the properties in snake_case.
/* eslint-disable camelcase */

import React from 'react';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PriceInput from 'components/Applauz/FormElements/PriceInput';

export default function CustomPriceModalContent({
  subscriptionPrice,
  subscriptionTypes,
  setSubscriptionPrice,
  dateIsValid,
}) {
  return (
    <form noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PriceInput
            id="standard-basic"
            label="Price Per Unit"
            name="ppu"
            value={subscriptionPrice.ppu}
            onChange={(e) =>
              setSubscriptionPrice({
                ...subscriptionPrice,
                ppu: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="package-picker">Package</InputLabel>
            <Select
              labelId="package-picker"
              value={`${subscriptionPrice.type || ''}`}
              placeholder="Select Subscription Type"
              onChange={(e) => {
                setSubscriptionPrice({
                  ...subscriptionPrice,
                  type: parseInt(e.target.value),
                });
              }}
            >
              {subscriptionTypes.map((subscriptionType) => {
                return (
                  <MenuItem
                    key={subscriptionType.value}
                    value={subscriptionType.value}
                  >
                    {subscriptionType.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="date-start"
            label="Start Date"
            type="date"
            value={subscriptionPrice.dt_start}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: { min: moment().format('YYYY-MM-DD') },
            }}
            onChange={(e) => {
              setSubscriptionPrice({
                ...subscriptionPrice,
                dt_start: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            disabled={!subscriptionPrice.dt_start}
            id="date-end"
            label="End Date"
            type="date"
            value={subscriptionPrice.dt_end}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: {
                min: moment(subscriptionPrice.dt_start)
                  .add(1, 'day')
                  .format('YYYY-MM-DD'),
              },
            }}
            onChange={(e) =>
              setSubscriptionPrice({
                ...subscriptionPrice,
                dt_end: e.target.value,
              })
            }
            error={!!subscriptionPrice.dt_end && !dateIsValid}
            helperText={
              !subscriptionPrice.dt_end || dateIsValid
                ? undefined
                : 'The end date must be later than the start date.'
            }
          />
        </Grid>
      </Grid>
    </form>
  );
}
