// We need to disable this because some endpoints
// expect the properties in snake_case.
/* eslint-disable camelcase */

import React from 'react';
import moment from 'moment';
import {
  CircularProgress,
  Box,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';

export default function UpcomingPlan({
  upcomingPlan,
  showUpcomingPlanDetails,
  onClickUpdatePlanButton,
  isLoading,
  loading,
}) {
  return (
    <Box sx={{ px: 2, pt: 2, pb: 1, width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center" spacing={2}>
            {/* SHOW UPCOMING PLAN IF DIFFERENT PLAN HAS BEEN SET */}
            <Grid item xs={8}>
              {showUpcomingPlanDetails && (
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={5}>
                    <Typography
                      variant="subtitle1"
                      style={{ textTransform: 'none', fontWeight: 500 }}
                    >
                      Upcoming Plan:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography
                      variant="body2"
                      style={{ textTransform: 'none' }}
                    >
                      {upcomingPlan.frequencyDisplayText}
                      <Box fontSize={12}>
                        {' '}
                        ({upcomingPlan.length} from{' '}
                        {moment(upcomingPlan.startDate).format('YYYY-MM-DD')})
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={4}>
              <Grid container justifyContent="flex-end">
                <Button
                  onClick={onClickUpdatePlanButton}
                  color="primary"
                  variant="outlined"
                  size="small"
                  disabled={loading}
                  style={{ textTransform: 'none' }}
                >
                  {showUpcomingPlanDetails
                    ? 'Update Upcoming Plan'
                    : 'Set Upcoming Plan'}
                  {isLoading && <CircularProgress size={24} />}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
