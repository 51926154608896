import React from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import BackIcon from '@material-ui/icons/ArrowBack';
import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import GenerateInvoice from './GenerateInvoice';
import Dropdown from './Dropdown';
import DropdownWithSearch from './DropdownWithSearch';
import GeneratedQuoteDialog from './GeneratedQuoteDialog';
import { useNewInvoiceOptions } from './useNewInvoiceOptions';
import useGeneratedQuote from './useGeneratedQuote';
import purchaseTypeCurrencyDefaults from './purchaseTypeCurrencyDefaults';

const TYPES = {
  account: 'account',
  purchase: 'purchase',
  currency: 'currency',
};

const NewInvoicePage = () => {
  const [selectedAccountId, setSelectedAccountId] = React.useState('');
  const [selectedPurchaseType, setSelectedPurchaseType] = React.useState('');
  const [selectedCurrency, setSelectedCurrency] = React.useState('');

  const generatedQuote = useGeneratedQuote(
    selectedPurchaseType,
    selectedAccountId,
    selectedCurrency
  );

  const navigate = useNavigate();

  const { accountItems, purchaseItems, currencyItems } = useNewInvoiceOptions();

  React.useEffect(() => {
    if (purchaseTypeCurrencyDefaults[selectedPurchaseType]) {
      /**
       * If the default currency of the purchase type needs to override
       * the default currency of the organization.
       */
      setSelectedCurrency(purchaseTypeCurrencyDefaults[selectedPurchaseType]);
    } else if (selectedAccountId) {
      /**
       * Otherwise, use the organization's default currency as the
       * default value of the currency for the purchase.
       */
      const selected = accountItems.find(
        (accountDetail) => accountDetail.value === selectedAccountId
      );
      setSelectedCurrency(
        currencyItems.find((option) => option.value === selected.currency)
          ?.value
      );
    }
  }, [accountItems, currencyItems, selectedAccountId, selectedPurchaseType]);

  const onChangeSelectedItems = React.useCallback((type, value) => {
    if (type === TYPES.account) return setSelectedAccountId(value);
    if (type === TYPES.purchase) return setSelectedPurchaseType(value);
    return setSelectedCurrency(value);
  }, []);

  return (
    <div>
      <Button
        style={{ paddingBottom: 5, paddingLeft: 3 }}
        color="github"
        link
        onClick={() => navigate('/admin/invoices')}
      >
        <Typography variant="button">
          <BackIcon />
          Back
        </Typography>
      </Button>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <GridContainer spacing={0}>
                <GridItem xs={12} md={5}>
                  <DropdownWithSearch
                    type={TYPES.account}
                    label="Account:"
                    placeholder="Select an account"
                    items={accountItems}
                    value={selectedAccountId}
                    onChange={onChangeSelectedItems}
                  />
                </GridItem>
                <GridItem xs={12} md={5}>
                  <Dropdown
                    label="Type of purchases:"
                    type={TYPES.purchase}
                    placeholder="Select a type"
                    items={purchaseItems}
                    value={selectedPurchaseType}
                    onChange={onChangeSelectedItems}
                  />
                </GridItem>
                <GridItem xs={12} md={2}>
                  <Dropdown
                    label="Currency:"
                    type={TYPES.currency}
                    placeholder=""
                    value={selectedCurrency}
                    items={currencyItems}
                    defaultValue={currencyItems[0]}
                    onChange={onChangeSelectedItems}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <GenerateInvoice
        selectedAccountId={selectedAccountId}
        selectedPurchaseType={selectedPurchaseType}
        selectedCurrency={selectedCurrency}
        onGeneratedQuoteURLClear={generatedQuote.clearURL}
        onQuoteGenerate={generatedQuote.generate}
      />

      <GeneratedQuoteDialog
        isOpen={generatedQuote.dialogIsOpen}
        onClose={generatedQuote.closeDialog}
        url={generatedQuote.url}
      />
    </div>
  );
};

export default NewInvoicePage;
