import React, { useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
// react component for creating dynamic tables
import MaterialTable from 'material-table';
import SearchIcon from '@material-ui/icons/Search';
import {
  InputAdornment,
  Input,
  InputLabel,
  IconButton,
  Box,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import InfoWrapper from 'components/Applauz/InfoWrapper';
import {
  getVoucherLicencePrice,
  updateVoucherLicencePrice,
} from 'helpers/api/awards';

const ServiceAwards = ({
  awards: { allAwards, active, redeemed, expired },
  organizationID,
}) => {
  const [licencePrice, setLicencePrice] = useState('loading...');

  useEffect(() => {
    getVoucherLicencePrice(organizationID).then((res) => {
      if (res.customPPU) return setLicencePrice(res.customPPU);
      setLicencePrice(res.defaultPPU);
    });
  }, []);

  const onSaveVoucherLicencePrice = () => {
    console.log({ organizationID }, licencePrice);
    updateVoucherLicencePrice(organizationID, licencePrice)
      .then((res) => {
        if (res.success) setLicencePrice(licencePrice);
      })
      .catch((UpdateVoucherLicencePriceError) => {
        console.error({ UpdateVoucherLicencePriceError });
        setLicencePrice('Error');
      });
    setLicencePrice('saving...');
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box display="flex" justifyContent="center">
          <div>
            <InputLabel htmlFor="custom-licence-amount">
              Voucher Licence Price
            </InputLabel>
            <Input
              id="custom-licence-amount"
              value={licencePrice}
              onChange={(event) =>
                setLicencePrice(parseFloat(event.target.value))
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="add"
                    onClick={onSaveVoucherLicencePrice}
                  >
                    <SaveIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
        </Box>
      </GridItem>
      <GridItem xs={6}>
        <InfoWrapper label="Total Vouchers" value={allAwards.length} />
        <InfoWrapper label="Active" value={active} />
      </GridItem>
      <GridItem xs={6}>
        <InfoWrapper label="Expired" value={expired} />
        <InfoWrapper label="Redeemed" value={redeemed} />
      </GridItem>
      <GridItem xs={12}>
        <MaterialTable
          title=""
          columns={[
            { title: 'Celebration Date', field: 'date', defaultSort: 'desc' },
            { title: 'User', field: 'user' },
            { title: 'Milestone', field: 'milestone' },
            {
              title: 'Amount',
              field: 'amount',
              render: (rowData) => (
                <NumericFormat
                  value={rowData.amount}
                  displayType={'text'}
                  thousandSeparator
                  prefix={`${rowData.currency} $`}
                  decimalScale={2}
                  fixedDecimalScale
                />
              ),
              align: 'right',
            },
            { title: 'Status', field: 'status' },
          ]}
          data={allAwards}
          options={{
            actionsColumnIndex: -1,
            search: false,
            toolbar: false,
          }}
          icons={{ Filter: SearchIcon }}
          style={{ boxShadow: 'none' }}
        />
      </GridItem>
    </GridContainer>
  );
};
export default ServiceAwards;
