import Button from '@material-ui/core/Button';

function SubmitButton({ label, disabled, onClick }) {
  return (
    <Button
      variant="contained"
      size="large"
      style={
        !disabled ? { color: 'white', backgroundColor: '#00acc1' } : undefined
      }
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </Button>
  );
}

export default SubmitButton;
