import { get, post } from '.';

export const getAccounts = (params) => {
  const url = `${process.env.REACT_APP_HUB_SERVER_URL}/accounts/accounts`;

  return get(url, { params });
};

export const getOrganizationCatalog = (organizationId) =>
  get(`${process.env.REACT_APP_HUB_SERVER_URL}/organization/catalog`, {
    params: { organizationId },
  });

export const getAvailableGRSCatalogs = (organizationId) =>
  get(
    `${process.env.REACT_APP_HUB_SERVER_URL}/organization/availableGRSCatalogs`,
    {
      params: { organizationId },
    }
  );

export const getSnappointsTransactions = () =>
  get(
    `${process.env.REACT_APP_HUB_SERVER_URL}/snappoints/organizationsTransactions`
  );

export const getOrganization = (id) =>
  get('as://users/admin/organization', { params: { id } });

export const getOrganizationsCount = (params) =>
  get('as://users/admin/organizationsCount', { params });

export const getAccount = (id) =>
  get('as://users/admin/getAccount', { params: { id } });

export const addAccount = (account) =>
  post('as://api/registration/addAccount', { params: { ...account } });

export const editAccount = (account) =>
  post('as://api/organizations/editAccount', { params: { ...account } });
