import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import Card from 'components/Card/Card';
import CardAvatar from 'components/Card/CardAvatar';
import Accordion from 'components/Accordion/Accordion';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button';
import Modal from 'components/Modal/Modal';

import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import InfoWrapper from 'components/Applauz/InfoWrapper';
import Loading from 'components/Loading/Loading';
import SnackbarContent from 'components/Snackbar/SnackbarContent';

import PersonalInfo from './Sections/PersonalInfo';

import Address from './Sections/Address';

import Invoices from './Sections/Invoices';

import Redemptions from './Sections/Redemptions';

import ServiceAwards from './Sections/ServiceAwards';

import { getUser, resetPassword, deleteUser } from 'helpers/api/users';

import { superAdminUrl } from 'variables/config';

const useStyles = makeStyles(styles);

const EditEmployee = () => {
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();

  const [user, setUser] = useState(null);
  const [error, setError] = useState(false);

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [message, setMessage] = useState({});
  const [visible, setVisible] = useState(false);

  const resetUserPassword = () => {
    if (user.status !== 1) {
      setMessage({ text: 'User is not active', color: 'rose' });
      setTimeout(() => {
        setMessage({});
      }, 2000);
      return;
    }

    resetPassword(params.id)
      .then(() => {
        setMessage({
          text: 'Password reseted succesfully',
          color: 'success',
        });

        setTimeout(() => {
          setMessage({});
        }, 2000);
      })
      .catch((resetPasswordError) => {
        console.log({ resetPasswordError });
        setMessage({
          text: 'Could not reset user password',
          color: 'rose',
        });
        setTimeout(() => {
          setMessage({});
        }, 2000);
      });
  };

  const deleteUserAccount = () => {
    deleteUser(params.id)
      .then(() => {
        navigate(`/admin/accounts/view/${user.organization_id}`);
      })
      .catch((deleteUserError) => {
        console.log({ deleteUserError });
      });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = () => {
    getUser(parseInt(params.id))
      .then(({ success, user }) => {
        if (success) setUser(user);
        else
          setError(
            `Could not get the user you are looking for. Please contact IT team`
          );
      })
      .catch((err) => {
        console.log(err);
        setError(
          `Could not get the user you are looking for. Please contact IT team`
        );
      });
  };
  if (error)
    return (
      <GridItem xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <SnackbarContent message={error} color="rose" />
      </GridItem>
    );

  if (!user)
    return (
      <GridItem xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <Loading />
      </GridItem>
    );

  console.log({ user });
  return (
    <GridContainer>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        cancelText="No"
        confirmText="Yes"
        onConfirm={deleteUserAccount}
        onClickCloseButton={() => setVisible(false)}
        body={`Are you sure you want to delete ${user.fullName}`}
        title={'Delete User'}
      />
      <GridItem xs={12} sm={12} md={4}>
        <Card>
          <CardAvatar profile>
            <a href="#pablo" onClick={(e) => e.preventDefault()}>
              <img src={user.imageSrc} alt="..." />
            </a>
          </CardAvatar>
          <CardBody>
            <h4 className={classes.cardTitle} style={{ textAlign: 'center' }}>
              {user.fullName}
            </h4>
            <InfoWrapper label="Date added" value={user.dateAded} />
            <InfoWrapper
              label="Status"
              value={user.status_id === 1 ? 'Active' : 'Not Active'}
            />
            <InfoWrapper label="Last activity" value={user.lastActivity} />

            {user.status_id === 1 ? (
              <InfoWrapper label="Date activated" value={user.activatedAt} />
            ) : (
              <InfoWrapper
                label="Date deactivated"
                value={user.deactivatedAt}
              />
            )}
          </CardBody>
        </Card>
        <Button
          onClick={() => {
            window.open(
              `${superAdminUrl}admin/accounts/login/${params.id}/v1`,
              '_blank'
            );
          }}
          color="github"
          fullWidth
          disabled={false}
        >
          {'V1 - Login As'}
        </Button>
        <Button
          onClick={() => {
            window.open(
              `${superAdminUrl}admin/accounts/login/${params.id}/v2`,
              '_blank'
            );
          }}
          color="github"
          fullWidth
          disabled={false}
        >
          {'V2 - Login As'}
        </Button>
        <Button
          onClick={resetUserPassword}
          color="warning"
          fullWidth
          disabled={false}
        >
          {'Reset Password'}
        </Button>

        <Button
          onClick={() => setVisible(true)}
          color="danger"
          fullWidth
          disabled={false}
        >
          {'Delete User'}
        </Button>
        <GridItem>
          {showSuccess && (
            <SnackbarContent
              message={'Successfuly Saved'}
              close
              color="success"
            />
          )}
          {showError && (
            <SnackbarContent
              message={'An error occured while saving the user data'}
              close
              color="rose"
            />
          )}
          {message.text && message.color && (
            <SnackbarContent message={message.text} color={message.color} />
          )}
        </GridItem>
      </GridItem>
      <GridItem xs={12} md={8}>
        <Card>
          <CardBody>
            <Accordion
              active={0}
              collapses={[
                {
                  title: 'Personal Information',
                  content: (
                    <PersonalInfo
                      user={user}
                      setShowSuccess={setShowSuccess}
                      setShowError={setShowError}
                    />
                  ),
                },
                {
                  title: 'Shipping address',
                  content: (
                    <Address
                      address={{ id: user.id, ...user.shippingAddress }}
                      setShowSuccess={setShowSuccess}
                      setShowError={setShowError}
                      type={'shipping'}
                      fetchUser={fetchUser}
                    />
                  ),
                },
                {
                  title: 'Billing address',
                  content: (
                    <Address
                      address={{ id: user.id, ...user.billingAddress }}
                      setShowSuccess={setShowSuccess}
                      setShowError={setShowError}
                      type={'billing'}
                      fetchUser={fetchUser}
                    />
                  ),
                },
                {
                  title: 'Points & service awards',
                  content: <ServiceAwards awards={user.awards} />,
                },
                {
                  title: 'Redemptions',
                  content: <Redemptions redemptions={user.redemptions} />,
                },
                {
                  title: 'Invoices',
                  content: <Invoices invoices={user.invoices} />,
                },
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default EditEmployee;
