import { get, post } from '.';

export const getProducts = (params) =>
  get('as://catalog/admin/products', { params });

export const getProductsCount = (params) =>
  get('as://catalog/admin/productsCount', { params });

export const getCategories = () => get('as://catalog/admin/categories');

export const getBrands = () => get('as://catalog/admin/brands');

export const getProduct = (productId) =>
  // Exception for legacy camelcase params
  // eslint-disable-next-line camelcase
  get('as://catalog/admin/products', { params: { product_id: productId } });

export const saveProduct = (params) =>
  post('as://catalog/admin/saveProduct', { params });

export const getSuppliers = (countryId) =>
  get(`${process.env.REACT_APP_HUB_SERVER_URL}/suppliers/getSuppliers`, {
    params: { countryId },
  }).then((suppliers) =>
    suppliers.map((supplier) => ({
      ...supplier,
      // Deprecated: supplier_id camel case is deprecated
      // eslint-disable-next-line camelcase
      supplier_id: supplier.supplierId,
    }))
  );

export const getGRSCatalogs = (countryId) =>
  get(`${process.env.REACT_APP_HUB_SERVER_URL}/catalogs/grsCatalogs`, {
    params: { countryId },
  });
