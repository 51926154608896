import React from 'react';
import { getAccounts } from 'helpers/api/accounts';

export const useNewInvoiceOptions = () => {
  const [accounts, setAccounts] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchAccounts = React.useCallback(() => {
    setAccounts([]);
    setIsLoading(true);
    getAccounts()
      .then((res) => {
        setIsLoading(false);
        setAccounts(res);
      })
      .catch((getAccountsError) => console.error({ getAccountsError }));
  }, []);

  React.useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts]);

  const accountItems = React.useMemo(() => {
    if (!accounts) return [];
    return accounts
      .filter((org) => org.name && !org.disable)
      .map((org) => {
        return {
          text: org.name,
          value: org.organizationId,
          currency: org?.currencyId === 1 ? 'cad' : 'usd',
        };
      })
      .sort((a, b) =>
        a.text.localeCompare(b.text, 'fr', { ignorePunctuation: true })
      );
  }, [accounts]);

  /**
   * The following items will be updated by subsequent stories.
   **/
  const purchaseItems = React.useMemo(() => {
    return [{ text: 'Licence subscription', value: 'licenceSubscription' }];
  }, []);

  const currencyItems = React.useMemo(() => {
    return [
      { text: 'USD', value: 'usd' },
      { text: 'CAD', value: 'cad' },
    ];
  }, []);

  return {
    accountItems,
    purchaseItems,
    currencyItems,
    isLoading,
  };
};
