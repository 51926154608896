import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// core components
import GridItem from 'components/Grid/GridItem';

import Loading from 'components/Loading/Loading';

import { loginAs } from 'helpers/api/users';

const LoginAs = () => {
  const params = useParams();
  useEffect(() => {
    loginAs(params.id)
      .then(({ url, token }) => {
        if (params.version === 'v2' && token) {
          window.location.href =
            `${process.env.REACT_APP_AUTH_URL}/auth`
            + `?client_id=${process.env.REACT_APP_SUPER_ADMIN_HUB_CLIENT_ID}`
            + `&response_type=code`
            + `&scope=openid%20offline_access`
            + `&token=${token}`
            + `&redirect_uri=${process.env.REACT_APP_HUB_CLIENT_URL}`;
        } else {
          window.location.href = url;
        }
      })
      .catch((loginAsError) => {
        console.log({ loginAsError });
      });
  }, [params.id, params.version]);

  return (
    <GridItem xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
      <Loading />
    </GridItem>
  );
};

export default LoginAs;
