// We need to disable this because some endpoints
// expect the properties in snake_case.
/* eslint-disable camelcase */

import React, { useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import MaterialTable, { MTableAction } from 'material-table';
import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import moment from 'moment';
import InfoWrapper from 'components/Applauz/InfoWrapper';
import UpdatePlanModal from './UpdatePlanModal';
import { getUpcomingPlan } from 'helpers/api/licences';
import UpcomingPlan from './UpcomingPlan';
import { patchLicences } from 'helpers/api/subscriptions';

function redNegative(value) {
  return value < 0 ? { color: 'red' } : {};
}

export default function CurrentPlan({
  currentSubscription,
  currentPlan,
  subscriptionTypes,
  isLoading,
  setSelectedPrice,
  setDeletePriceModalIsOpen,
  setCustomPriceModalIsOpen,
}) {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const { data: upcomingPlan } = useQuery(
    ['upcomingPlan', parseInt(currentPlan?.organization_id)],
    async () => {
      const upcomingPlanData = await getUpcomingPlan({
        organizationId: parseInt(currentPlan?.organization_id),
      });
      return upcomingPlanData;
    },
    { placeholderData: {} }
  );
  const [nextBillDate, setNextBillDate] = React.useState(null);
  const [deactivationDate, setDeactivationDate] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setNextBillDate(currentSubscription.dtNextBill?.substring(0, 10));
    setDeactivationDate(currentSubscription.dtDeactivated?.substring(0, 10));
  }, [currentSubscription]);

  const patchWithLoading = (params) => {
    setLoading(true);
    patchLicences({
      organizationId: currentPlan.organization_id,
      licenceTypeId: currentSubscription.typeId,
      ...params,
    })
      .then((response) => {
        if (response.success) {
          queryClient.invalidateQueries('account');
          queryClient.invalidateQueries('account.currentSubscription');
        }
        setLoading(false);
      })
      .catch((patchLicencesError) => {
        alert(patchLicencesError);
        console.log({ patchLicencesError });
        setLoading(false);
      });
  };

  const hasUpcomingPlan = useMemo(
    () =>
      currentSubscription
      && upcomingPlan
      && (currentSubscription.total !== upcomingPlan.total
        || currentSubscription.pricePerLicence !== upcomingPlan.pricePerLicence
        || currentSubscription.frequency !== upcomingPlan.frequency),
    [upcomingPlan, currentSubscription]
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper variant="outlined">
            <InfoWrapper
              label="Total licences"
              value={currentSubscription.total}
            />

            <InfoWrapper
              label="Licences used"
              value={currentSubscription.licencesUsed}
            />

            <InfoWrapper
              label="Plan"
              value={`${currentSubscription.frequency} - ${currentSubscription.frequencyDisplayText}`}
            />

            <InfoWrapper
              label="Price per licence"
              value={`$${currentSubscription.pricePerLicence?.toFixed(2)} ${
                currentSubscription.priceCurrency
              }`}
              valueStyle={redNegative(currentSubscription.pricePerLicence)}
            />

            <InfoWrapper
              label="Prorated Price"
              value={`${currentSubscription.proratedPrice?.toFixed(
                2
              )} (for the current date)`}
              valueStyle={redNegative(currentSubscription.proratedPrice)}
            />

            <InfoWrapper
              label="Prorated Credit"
              value={`${currentSubscription.proratedCredit?.toFixed(
                2
              )} (for the current date)`}
            />
            <InfoWrapper
              label="Plan start date"
              value={`${currentSubscription.startDate?.substring(0, 10)}`}
            />
            <InfoWrapper
              label="Plan end date"
              value={`${currentSubscription.endDate?.substring(0, 10)}`}
            />
            <InfoWrapper
              label="Number of days left"
              value={`${currentSubscription.numberOfDaysLeft}`}
              valueStyle={redNegative(currentSubscription.numberOfDaysLeft)}
            />

            <InfoWrapper
              label="Next bill date"
              value={
                <>
                  <TextField
                    id="date-start"
                    label=""
                    type="date"
                    value={nextBillDate}
                    onChange={(e) => setNextBillDate(e.target.value)}
                  />
                  <Button
                    onClick={() =>
                      patchWithLoading({ dtNextBill: nextBillDate })
                    }
                    color="primary"
                    variant="outlined"
                    style={{ textTransform: 'none', lineHeight: '0.2em' }}
                    disabled={loading}
                  >
                    Set Next bill date
                  </Button>
                </>
              }
            />
            <InfoWrapper
              label="Deactivation date"
              value={
                !!currentSubscription.dtDeactivated && (
                  <>
                    <TextField
                      id="date-start"
                      label=""
                      type="date"
                      value={deactivationDate}
                      onChange={(e) => setDeactivationDate(e.target.value)}
                    />
                    {deactivationDate && (
                      <Typography color="error">
                        {' '}
                        {moment(deactivationDate).isBefore(nextBillDate)
                          ? '⚠️ before next bill date ⚠️'
                          : ''}
                      </Typography>
                    )}

                    <Button
                      onClick={() =>
                        patchWithLoading({ dtDeactivated: deactivationDate })
                      }
                      color="primary"
                      variant="outlined"
                      style={{ textTransform: 'none', lineHeight: '0.2em' }}
                      disabled={loading}
                    >
                      Set deactivation date
                    </Button>
                  </>
                )
              }
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper variant="outlined">
            <MaterialTable
              title="Custom Prices"
              columns={[
                { title: 'Plan', field: 'type' },
                {
                  title: 'Start Date',
                  field: 'start',
                },
                { title: 'End Date', field: 'end' },
                { title: 'Price Per user', field: 'ppu' },
              ]}
              data={[...currentPlan.customPrices].map((i) => ({
                ...i,
                type:
                  subscriptionTypes.find((j) => i.type_id === j.value)?.name
                  || i.type,
              }))}
              options={{
                actionsColumnIndex: -1,
                paging: true,
                pageSize: 5,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [5],
                search: false,
              }}
              actions={[
                () => ({
                  icon: 'cancel',
                  iconProps: { style: { color: 'red' } },
                  tooltip: 'Delete Custom Price',
                  onClick: (_, { type_id, id }) => {
                    setSelectedPrice({ type_id, id });
                    setDeletePriceModalIsOpen(true);
                  },
                }),
                {
                  icon: 'add',
                  tooltip: 'Add new Custom Price',
                  isFreeAction: true,
                  onClick: () => setCustomPriceModalIsOpen(true),
                },
              ]}
              style={{ boxShadow: 'none' }}
              components={{
                // This is intended.
                // eslint-disable-next-line react/no-multi-comp
                Action: (props) => {
                  if (props.action.icon === 'add') {
                    return (
                      <Button
                        onClick={(event) =>
                          props.action.onClick(event, props.data)
                        }
                        color="primary"
                        variant="outlined"
                        style={{ textTransform: 'none' }}
                        size="small"
                      >
                        Add new Custom Price
                      </Button>
                    );
                  }
                  return <MTableAction {...props} />;
                },
              }}
            />
          </Paper>
        </Grid>

        {/** UPCOMING PLAN SECTION */}

        <UpcomingPlan
          upcomingPlan={upcomingPlan}
          showUpcomingPlanDetails={hasUpcomingPlan}
          onClickUpdatePlanButton={() => setIsOpen(true)}
          isLoading={isLoading}
          loading={loading}
        />
      </Grid>

      <UpdatePlanModal
        currentPlan={currentPlan}
        upcomingPlan={upcomingPlan}
        isOpen={isOpen}
        title={'Update Plan'}
        // actions
        onClose={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        onClickCloseButton={() => setIsOpen(false)}
      />
    </>
  );
}
