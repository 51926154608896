import { post, patch } from '.';

export const updatePrice = (params) =>
  post('as://subscriptions/admin/updatePrice', { params });

export const cancelPrice = (params) =>
  post('as://subscriptions/admin/cancelPrice', { params });

export const patchLicences = ({
  organizationId,
  licenceTypeId,
  dtNextBill,
  dtDeactivated,
}) =>
  patch(
    `${process.env.REACT_APP_HUB_SERVER_URL}/licences/organizations/${organizationId}/licences/${licenceTypeId}`,
    {
      params: { dtNextBill, dtDeactivated },
    }
  );
