import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

const Dropdown = ({
  type,
  label,
  items,
  placeholder,
  value,
  defaultValue,
  onChange,
}) => {
  const onChangeHandler = (e) => {
    const selected = items.filter((item) => item.value === e.target.value);
    onChange(type, selected[0].value);
  };

  React.useEffect(() => {
    if (defaultValue) {
      onChange(type, defaultValue.value);
    }
  }, [placeholder, defaultValue, onChange, type]);

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="multiple-select" id={`label-id-${type}`}>
        {label}
      </InputLabel>
      <Select
        labelId={`label-id-${type}`}
        id={`select-${type}`}
        value={value || ''}
        onChange={onChangeHandler}
        displayEmpty
      >
        {!!placeholder && (
          <MenuItem disabled value="none">
            {placeholder}
          </MenuItem>
        )}

        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
