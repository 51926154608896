const licenceVolumeDiscounts = [
  {
    licencesCountRange: [251, 500],
    value: 0.05,
  },
  {
    licencesCountRange: [501, 750],
    value: 0.1,
  },
  {
    licencesCountRange: [751, 1000],
    value: 0.15,
  },
  {
    licencesCountRange: [1001, 2000],
    value: 0.2,
  },
  {
    licencesCountRange: [2001, 3000],
    value: 0.25,
  },
  {
    licencesCountRange: [3001, 4000],
    value: 0.3,
  },
  {
    licencesCountRange: [4001, 5000],
    value: 0.35,
  },
  {
    licencesCountRange: [5001],
    value: 0.4,
  },
];

export default licenceVolumeDiscounts;
